<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Số lượng lao động trong nước</b-card-title>
      </b-card-header>

      <!-- chart -->
      <b-card-body>
        <vue-apex-charts
          type="bar"
          :options="chartOptions"
          :series="series"
          height="400px"
        />
      </b-card-body>
    </b-card>
    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :hideDelete="false"
        :hideImportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        class="custtom-header pr-1"
        @clickExportExcel="dowloadFile"
        @search="search($event)"
      />
      <!--Phần header của bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
      />
      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />
    </div>
  </div>

</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import configChart from '../configChart'

import store from '../../store'
import MyPagination from '@/components/pagination/MyPagination.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    VueApexCharts,
  },
  data() {
    return {
      districtList: [],
      series: [],
      chartOptions: {},
      temp: configChart,
      dataList: [],
      // ==dữ liệu bảng
      rows: [
      ],
      columns: [
        {
          label: 'HUYỆN',
          field: 'name',
        },
        {
          label: 'SỐ LƯỢNG LAO ĐỘNG',
          field: 'value',

        },
      ],
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },

      // ==phân trang
      totalRecord: 0,
      currentPage: 10,
    }
  },

  created() {
    this.fetchData(this.urlQuery)
  },

  methods: {

    async dowloadFile() {
      const name = 'DangLamViecTrongNuoc.xlsx'
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_EXCEL)
    },
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    // Fetch data table
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_WORKER_ON_BROAD, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.series = [
        {
          name: 'Số lượng lao động',
          data: this.dataList.map(e => e.value),
        },
      ]
      this.districtList = this.dataList.map(e => e.name)
      const lists = []
      this.districtList.forEach(e => {
        if (e.split(' ').length > 2) {
          lists.push(e.split(' '))
        } else {
          lists.push([e])
        }
      })
      lists.forEach((item, index, arr) => {
        if (item.length > 2) {
          let counter = 0
          let str = ''
          const result = []
          for (let i = 0; i < item.length; i++) {
            if (counter === 2) {
              result.push(`${str} ${item[i]}`)
              counter = 0
              str = ''
            } else {
              counter += 1
              str = `${str} ${item[i]}`
            }
          }
          if (str) {
            result.push(str)
          }
          arr.splice(index, 1, result)
        }
        return item
      })
      if (lists.length > 0) {
        this.temp.xaxis.categories = lists
      } else {
        this.temp.xaxis.categories = ['']
      }
      this.chartOptions = {}
      this.$nextTick(() => {
        this.chartOptions = this.temp
      })
      this.$hideLoading()
    },

    // Lấy danh sách tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchData(this.urlQuery)
      }
    },
  },

}
</script>

<style>

</style>
